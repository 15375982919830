import jQuery from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";
import {
    disableSsoButton,
    findAppleSsoButton,
    findGoogleSsoButton,
    initAppleIdentityButton,
    initGoogleIdentityButton,
    updateSsoButtonLabel,
} from "ynab_api/helpers/sso_button";
import { AuthenticationProvider } from "./types";
import { AnalyticsEvents, AnalyticsProperties, AnalyticsPropertyValues } from "ynab_api/analytics-events";

/**
 * ⚠️⚠️⚠️
 * This code was moved from legacy global JS code and it has
 * NOT been refactored as an idiomatic Stimulus controller.
 * ⚠️⚠️⚠️
 */
export default class extends Controller {
    declare referAFriendLinkTarget: HTMLElement;

    static targets: string[] = ["referAFriendLink"];

    public connect(): void {
        this.setupLogoutLink();
        this.setupUserOptionsForm();
        this.setupPasswordResetForm();
        this.setupSSOErrorHandling();
    }

    public referAFriend(e: Event): void {
        e.preventDefault();

        this.referAFriendLinkTarget.setAttribute("disabled", "true");

        YNABAnalytics.trackEvent(AnalyticsEvents.General_ClickedReferralProgramButton, {
            [AnalyticsProperties.Location]: AnalyticsPropertyValues.SettingsPage,
        });

        setTimeout(() => {
            // give the analytics event a chance to fire before navigating
            window.location.href = this.referAFriendLinkTarget.dataset.url;
        }, 300);
    }

    private setupLogoutLink(): void {
        jQuery(".logout-link").on("click", (event) => {
            const button = jQuery(event.target);
            if (button.data("ujs:disabled")) {
                return Rails.stopEverything(event);
            }

            const logoutUrl = button.data("logout-url");
            const redirectUrl = button.data("redirect-url");

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            jQuery
                .ajax(logoutUrl, {
                    method: "POST",
                    headers: { "X-Session-Token": YNAB.getSessionToken() },
                })
                .then(() => {
                    YNABAnalytics.trackLogout();
                    YNABAnalytics.logoutOfChatWidgetProviders();
                    YNAB.redirectWithDelay(redirectUrl);
                });
        });
    }

    private setupUserOptionsForm(): void {
        jQuery("#change_user_options_form")
            .on("change", "input[type=checkbox]", function () {
                Rails.fire(this.form, "submit");
            })
            .on("ajax:success", (event) => {
                const xhr = event.detail[2];
                const form = event.target;
                jQuery(form).replaceWith(xhr.responseText);
                this.setupUserOptionsForm();
            });
    }

    private setupPasswordResetForm(): void {
        jQuery(".set-password-form").each(function () {
            let form = jQuery(this);
            form.validate();
            new YNAB.FormDirtyTrackingAllFieldsPresent(form);

            form.parent()
                .on("ajax:error", ".set-password-form", (event) => {
                    const xhr = event.detail[2];
                    if (xhr.status == 422) {
                        const nextForm = jQuery(xhr.responseText);
                        form.replaceWith(nextForm);
                        form = nextForm;
                        form.validate();
                        new YNAB.FormDirtyTrackingAllFieldsPresent(form);
                    } else {
                        window.alert("There was an unexpected error.");
                    }
                })
                .on("ajax:success", ".set-password-form", (_event) => {
                    requestAnimationFrame(() => {
                        Rails.disableElement(form.get(0));
                    });
                    window.location.reload();
                });
        });
    }

    private get $ssoCredentialForm() {
        return jQuery("#create_sso_credential_form");
    }

    private setupSSOErrorHandling(): void {
        if (this.$ssoCredentialForm.length) {
            findGoogleSsoButton()
                .on("click", () => this.hideSSOError())
                .on("googleIdentified:success", (_, data) => {
                    this.submitSSOCredentialForm(AuthenticationProvider.Google, data.token);
                })
                .on("googleIdentified:error", (_, error) => {
                    this.showSSOError(error);
                });

            initGoogleIdentityButton();

            const appleSSOButton = findAppleSsoButton();
            appleSSOButton.on("apple:ready", (_event, auth) => {
                if (auth.error) {
                    this.showSSOError(auth.errorMessage);
                } else if (auth.token) {
                    disableSsoButton(appleSSOButton.get(0));
                    updateSsoButtonLabel(appleSSOButton.get(0), "Connecting...");
                    this.submitSSOCredentialForm(AuthenticationProvider.Apple, auth.token, auth.name);
                }
            });

            initAppleIdentityButton();
        }
    }

    private submitSSOCredentialForm(provider: AuthenticationProvider, token: string, name = ""): void {
        const $form = this.$ssoCredentialForm;
        $form.find("#sso_credential_provider").val(provider);
        $form.find("#sso_credential_token").val(token);
        $form.find("#sso_credential_name").val(name);
        $form.trigger("submit");
    }

    private showSSOError(error: string): void {
        jQuery("#sso-error").text(error).show();
    }

    private hideSSOError(): void {
        jQuery("#sso-error").hide();
    }
}
